import { useState, useRef, useEffect, useContext } from "react";
import { useMsal, useIsAuthenticated } from "@azure/msal-react";

import { useNavigate } from "react-router-dom";
import { Button } from "@element/react-components";

import HomeLogo from "../assets/images/Logo.png";
import BayerLogo from "../assets/images/BayerLogo.svg";
// import GroupLogo from "../assets/images/GroupLogo.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBell,
  faUser,
  faPoll,
  faCheck,
  faHourglassEnd,
  faXmark,
  faQuestion,
  faBookmark,
  faSignOutAlt,
  faCommentAlt,
} from "@fortawesome/free-solid-svg-icons";
// import Usercontext from "../utils/Usercontext";
import "../styles/Layout.css";

export const PageLayout = (props) => {
  const navigate = useNavigate();
  const isLoggedIn = useIsAuthenticated();
  const { instance, accounts } = useMsal();
  const [userName, setUserName] = useState();
  // const { notificationitems, setNotificationitems } = useContext(Usercontext);

  const wrapperRef = useRef(null);
  const profileRef = useRef(null);
  const [messages, setMessages] = useState([]);
  const [showprofilemenu, setShowprofilemenu] = useState(false);
  const [showNotifications, setShowNotifications] = useState(false);

  // const removeTIcket = (id) => {
  //   const temp = notificationitems;
  //   const objIndex = temp.findIndex((obj) => obj["id"] === parseInt(id));
  //   temp[objIndex].read = 1;
  //   setNotificationitems(temp);
  //   const tempmsg = messages.filter((obj) => obj["id"] !== parseInt(id));
  //   setMessages(tempmsg);
  // };

  const handleClickOutside = (event) => {
    if (
      wrapperRef.current &&
      !wrapperRef.current.contains(event.target) &&
      !showNotifications
    ) {
      setShowNotifications(false);
    }
    if (
      profileRef.current &&
      !profileRef.current.contains(event.target) &&
      !showprofilemenu
    ) {
      setShowprofilemenu(false);
    }
  };

  const handleLogout = () => {
    instance.logoutRedirect();
  };

  const goHome = () => {
    navigate("/");
  };

  const goResults = () => {
    navigate("/results");
  };

  useEffect(() => {
    const currentAccount = accounts[0];
    if (currentAccount) {
      setUserName(currentAccount.name);
    }
    if (!isLoggedIn) {
      navigate("/");
    }
    // const unreadMsgs = notificationitems.filter((itm) => itm.read === 0);
    // setMessages(unreadMsgs);

    //  MSAL
    // if (accounts[0] !== undefined) {
    //   setUserName(accounts[0].name);
    // }

    document.addEventListener("click", handleClickOutside, false);
    return () => {
      document.removeEventListener("click", handleClickOutside, false);
    };
  }, [accounts]); // notificationitems

  return (
    <>
      <div className="header">
        <div className="header_logo">
          <a onClick={() => goHome()}>
            <img
              src={BayerLogo}
              alt="Bayer Logo"
              className="header_logo_bayer"
            />
          </a>
          <span className="header_logo_line"></span>
          {/*
          <a onClick={() => goHome()}>
            <img
              src={GroupLogo}
              alt="Group Logo"
              className="header_logo_atlasone"
            />
          </a>
          */}
          <div className="header_title">
            <a onClick={() => goHome()}>
              <img
                src={HomeLogo}
                alt="Home Logo"
                className="header_logo_atlasone"
              />
            </a>
          </div>
        </div>
        {isLoggedIn && (
          <ul className="userheader">
            <li>
              <a onClick={() => goResults()} className="resultslink">
                <FontAwesomeIcon icon={faPoll} />
                &nbsp;Results
              </a>
            </li>
            <li ref={wrapperRef}>
              <a
                className="white"
                onClick={() => setShowNotifications(!showNotifications)}
              >
                <FontAwesomeIcon icon={faBell} />
              </a>
              {messages.length > 0 && (
                <span
                  className="notify"
                  onClick={() => setShowNotifications(!showNotifications)}
                >
                  {messages.length}
                </span>
              )}
              {showNotifications && (
                <ul className="notifications">
                  {messages.length > 0 &&
                    messages.map((item, i) => (
                      <li key={i}>
                        <span className="notificationicon">
                          {item.done ? (
                            <FontAwesomeIcon icon={faCheck} />
                          ) : (
                            <FontAwesomeIcon icon={faHourglassEnd} />
                          )}
                        </span>
                        <span className="notificationtext">{item.name}</span>
                        <span
                          className="notificationclose"
                          onClick={() => /*removeTIcket(item.id)*/ {}}
                        >
                          <FontAwesomeIcon icon={faXmark} />
                        </span>
                      </li>
                    ))}
                  {messages.length === 0 ? (
                    <li className="text-center marginbot50">
                      You have cleared all the notifications
                    </li>
                  ) : (
                    ""
                  )}
                  <li>
                    <Button
                      type="submit"
                      variant="outlined"
                      label="Clear all notification"
                      onClick={() => setMessages([])}
                      className={
                        messages.length > 0
                          ? "btnclearnotify active"
                          : "btnclearnotify disabled"
                      }
                      disabled={messages.length > 0 ? false : true}
                    />
                    {/*
                      <button
                        className="btnclearnotify active"
                        onClick={() => setMessages([])}
                      >
                        Clear all notification
                      </button>
                    */}
                  </li>
                </ul>
              )}
            </li>
            <li ref={profileRef}>
              <a
                className="blue"
                onClick={() => setShowprofilemenu(!showprofilemenu)}
              >
                <FontAwesomeIcon icon={faUser} />
              </a>
              {showprofilemenu && (
                <ul className="userprofile">
                  <li>
                    <span className="userprofile_img">
                      <img src="https://cdn-icons-png.flaticon.com/512/149/149071.png" />
                    </span>
                    <strong>{userName}</strong>
                  </li>
                  <li>
                    <span className="userprofile_icon">
                      <FontAwesomeIcon icon={faQuestion} />
                    </span>
                    Help & Support
                  </li>
                  <li>
                    <span className="userprofile_icon left25">
                      <FontAwesomeIcon icon={faCommentAlt} />
                    </span>
                    FAQ
                  </li>
                  <li>
                    <span className="userprofile_icon left25">
                      <FontAwesomeIcon icon={faBookmark} />
                    </span>
                    Guides
                  </li>
                  <li>
                    <a onClick={() => handleLogout()}>
                      <span className="userprofile_icon">
                        <FontAwesomeIcon icon={faSignOutAlt} />
                      </span>
                      Logout
                    </a>
                  </li>
                </ul>
              )}
            </li>
          </ul>
        )}
      </div>
      <div className="container">{props.children}</div>
    </>
  );
};
