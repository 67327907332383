import { Button } from "@element/react-components";

const Launchmessage = (props) => {
  return (
    <>
      <div className="row">
        <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
          <div
            className={
              props.launchMessage.valid
                ? "launchmsg success"
                : "launchmsg error"
            }
          >
            <h5>Launch Optimization</h5>
            <p className="text-center">{props.launchMessage.messagetxt}</p>
            {props.launchMessage.valid ? (
              <Button
                label="RUN!"
                type="submit"
                className="btn"
                variant="outlined"
                onClick={() => props.finalinputdata("step5", {})}
              />
            ) : (
              // <button
              //   className="btn"
              //   onClick={() => props.finalinputdata("step5", {})}
              // >
              //   RUN!
              // </button>
              ""
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Launchmessage;
