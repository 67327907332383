export const basePath = "https://dev.api.optimrna.int.bayer.com/api/";
// "https://aml-mrna-api-backend-01.azurewebsites.net/api/";

export const axiosOptions = {
  "Content-Type": "application/json",
};

export const tissueList = [
  { text: "Follipian tube", value: "Follipiantube" },
  { text: "Lung", value: "Lung" },
  { text: "Breast", value: "Breast" },
  { text: "Skin", value: "Skin" },
  { text: "Spleen", value: "Spleen" },
  { text: "Heart", value: "Heart" },
  { text: "Liver", value: "Liver" },
  { text: "Salivarygland", value: "Salivarygland" },
  { text: "Muscle", value: "Muscle" },
  { text: "Skeletal", value: "Skeletal" },
  { text: "Tonsil", value: "Tonsil" },
  { text: "Smallintestine", value: "Smallintestine" },
  { text: "Placenta", value: "Placenta" },
  { text: "Appendices", value: "Appendices" },
  { text: "Testis", value: "Testis" },
  { text: "Rectum", value: "Rectum" },
  { text: "Urinarybladder", value: "Urinarybladder" },
  { text: "Prostate", value: "Prostate" },
  { text: "Esophagus", value: "Esophagus" },
  { text: "Kidney", value: "Kidney" },
  { text: "Thyroid", value: "Thyroid" },
  { text: "Lymphnode", value: "Lymphnode" },
  { text: "Artery", value: "Artery" },
  { text: "Brain", value: "Brain" },
  { text: "Nerve", value: "Nerve" },
  { text: "Tibial", value: "Tibial" },
  { text: "Gallbladder", value: "Gallbladder" },
  { text: "Uterus", value: "Uterus" },
  { text: "Pituitary", value: "Pituitary" },
  { text: "Colon", value: "Colon" },
  { text: "Vagina", value: "Vagina" },
  { text: "Duodenum", value: "Duodenum" },
  { text: "Fat", value: "Fat" },
  { text: "Stomach", value: "Stomach" },
  { text: "Adrenal", value: "Adrenal" },
  { text: "Smoothmuscle", value: "" },
  { text: "Pancreas", value: "Pancreas" },
  { text: "Ovary", value: "Ovary" },
];
// "https://aml-mrna-api-backend-01.azurewebsites.net/api/";
