import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
// import { CSVLink } from "react-csv";
// import { DownloadTableExcel } from "react-export-table-to-excel";

import Loader from "../elements/loader/Loader";
import { basePath, axiosOptions } from "../utils/constants";
import axios from "axios";

const Viewresult = () => {
  const navigate = useNavigate();
  const resultId = useParams().id;
  const [list, setList] = useState({});
  const [result, setResult] = useState([]);
  const [loading, setLoading] = useState(false);
  const [opHeaders, setOpHeaders] = useState([]);
  const tableRef = useRef(null);

  const ipHeaders = [
    { label: "Job Id", key: "jobId" },
    { label: "Protein name", key: "name" },
    { label: "Protein sequence", key: "proteinSequence" },
    { label: "Algorithm", key: "algorithm" },
    { label: "Tissue", key: "tissue" },
    { label: "Number of sequences", key: "number_sequences" },
  ];

  const goBack = () => {
    navigate("/results");
  };

  const getResult = async () => {
    setLoading(true);
    await axios
      .get(`${basePath}jobs`, axiosOptions)
      .then((res) => {
        const temp = res.data.filter((itm) => itm.jobId === parseInt(resultId));
        setList(temp[0]);
        setLoading(false);
      })
      .catch((er) => {
        setLoading(false);
      });
  };

  const loadDataCall = async () => {
    setLoading(true);
    await axios
      .get(`${basePath}rnaSequenceByJobId/${resultId}`, axiosOptions)
      .then((res) => {
        setResult(res.data);
        setLoading(false);
        let tmp = [];
        if ("GC" in res.data[0]) {
          tmp.push("GC");
        }
        if ("U" in res.data[0]) {
          tmp.push("U");
        }
        if ("kCal" in res.data[0]) {
          tmp.push("kCal");
        }
        setOpHeaders(tmp);
      })
      .catch((er) => {
        setLoading(false);
      });
  };

  function downloadFile() {
    let csv_data = [];
    let rows = document.getElementsByTagName("tr");
    for (let i = 0; i < rows.length; i++) {
      let cols = rows[i].querySelectorAll("td,th");

      // Stores each csv row data
      let csvrow = [];
      for (let j = 0; j < cols.length; j++) {
        csvrow.push(cols[j].innerHTML);
      }
      csv_data.push(csvrow.join(","));
    }
    // Combine each row data with new line character
    csv_data = csv_data.join("\n");
    // Call this function to download csv file
    downloadCSVFile(csv_data);
  }

  function downloadCSVFile(csv_data) {
    const CSVFile = new Blob([csv_data], {
      type: "text/csv",
    });
    let temp_link = document.createElement("a");
    temp_link.download = "GfG.csv";
    let url = window.URL.createObjectURL(CSVFile);
    temp_link.href = url;
    temp_link.style.display = "none";
    document.body.appendChild(temp_link);
    temp_link.click();
    document.body.removeChild(temp_link);
  }

  useEffect(() => {
    getResult();
    loadDataCall();
  }, []);

  return (
    <>
      <div className="row margintop1">
        <div className="col-lg-6 col-md-6 col-sm-6 col-6 text-left">
          <a className="buttonlink" onClick={() => goBack()}>
            BACK
          </a>
        </div>
        <div className="col-lg-6 col-md-6 col-sm-6 col-6 text-right">
          {/* <DownloadTableExcel
            sheet="users"
            filename={`job_${resultId}.xlsx`}
            currentTableRef={tableRef.current}
          > */}
          <button className="csvbutton" onClick={() => downloadFile()}>
            Download File
          </button>
          {/* </DownloadTableExcel> */}
        </div>
      </div>
      <div className="row margintop1">
        <div
          id="resulttableId"
          className="col-lg-12 col-md-12 col-sm-12 col-12"
        >
          <table className="table fittable" ref={tableRef}>
            <tr>
              <th colSpan={8}>INPUT</th>
              {/* <td colSpan={4}>
                <CSVLink
                  data={[list]}
                  headers={ipHeaders}
                  className="csvbutton"
                  filename={`job_${resultId}_input`}
                >
                  Download CSV
                </CSVLink>
              </td> */}
            </tr>
            <tr>
              <th colSpan={4}>Protein name</th>
              <td colSpan={4}>{list?.name}</td>
            </tr>
            <tr>
              <th colSpan={4}>Protein sequence</th>
              <td colSpan={4}>{list?.proteinSequence}</td>
            </tr>
            <tr>
              <th colSpan={4}>Algorithm</th>
              <td colSpan={4}>{list?.algorithm}</td>
            </tr>
            <tr>
              <th colSpan={4}>Tissue</th>
              <td colSpan={4}>{list?.tissue}</td>
            </tr>
            <tr>
              <th colSpan={4}>Number of sequences</th>
              <td colSpan={4}>{list?.number_sequences}</td>
            </tr>
            <tr>
              <th colSpan={8}>OUTPUT</th>
            </tr>
            <tr>
              <th>mRNA ID</th>
              <th>mRNA sequence</th>
              <th>CAI</th>
              <th>CPB</th>
              <th>ENC</th>
              <th>MFE</th>
              <th>MFEini</th>
              <th>Score</th>
              {opHeaders.indexOf("GC") >= 0 && <th>GC_content</th>}
              {opHeaders.indexOf("U") >= 0 && <th>U_content</th>}
              {opHeaders.indexOf("kCal") >= 0 && <th>MFE (kcal/mol)</th>}
            </tr>
            {result.length > 0 &&
              result.map((item, i) => {
                return (
                  <tr key={i}>
                    <td>{item["RNAId"]}</td>
                    <td>{item["sequence"]}</td>
                    <td>{item["CAI"]}</td>
                    <td>{item["CPB"]}</td>
                    <td>{item["ENC"]}</td>
                    <td>{item["MFE"]}</td>
                    <td>{item["MFEini"]}</td>
                    <td>{item["score"]}</td>
                    {opHeaders.indexOf("GC") >= 0 && <td>{item["GC"]}</td>}
                    {opHeaders.indexOf("U") >= 0 && <td>{item["U"]}</td>}
                    {opHeaders.indexOf("kCal") >= 0 && <td>{item["kCal"]}</td>}
                  </tr>
                );
              })}
          </table>
        </div>
      </div>
      {loading && <Loader />}
    </>
  );
};

export default Viewresult;
