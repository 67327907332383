import { useState } from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../authConfig";

export const SignInButton = () => {
  const { instance } = useMsal();

  const [anchorEl, setAnchorEl] = useState(null);

  const handleLogin = async (loginType) => {
    setAnchorEl(null);

    if (loginType === "popup") {
      instance.loginPopup({
        ...loginRequest,
        redirectUri: process.env.REACT_APP_POPUP_REDIRECT_URI,
      });
      const request = { scopes: ["openid", "profile"] };
      await instance.acquireTokenPopup(request);
    } else if (loginType === "redirect") {
      instance.loginRedirect(loginRequest);
    }
  };

  return (
    <div className="signinbtn">
      <button onClick={(e) => handleLogin("redirect")}>
        SIGN IN WITH SINGLE SIGN-ON(SSO)
      </button>
    </div>
  );
};
