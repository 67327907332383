import SignInSignOutButton from "../utils/SignInSignOutButton";

const LoginBody = () => {
  return (
    <>
      <SignInSignOutButton />
    </>
  );
};

export default LoginBody;
