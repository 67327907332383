import React, { useState, useEffect } from "react"; // useContext
import axios from "axios";
import { useMsal } from "@azure/msal-react";
import { basePath, axiosOptions } from "../utils/constants";
import Resultspaginator from "../helpers/Resultspaginator";

// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faEye, faTrash } from "@fortawesome/free-solid-svg-icons";
// import Usercontext from "../utils/Usercontext";
// import results from "./../assets/data/results.json";

const Results = () => {
  const { accounts } = useMsal();
  const [results, setResults] = useState([]);
  const [username, setUserName] = useState("");

  // const { notificationitems, setNotificationitems } = useContext(Usercontext);
  // const [page, setPage] = useState(0);
  // // const [filterData, setFilterData] = useState();
  // const n = 5;
  // const deleteItem = (id) => {
  //   if (confirm("Are you sure you want to delete this ?")) {
  //     const filtered = displayResults.filter((item) => {
  //       return item["id"] !== id;
  //     });
  //     setDisplayResults(filtered);
  //     setNotificationitems(filtered);
  //   }
  // };

  const getResults = async (user_name = username) => {
    await axios
      .get(`${basePath}jobs`, axiosOptions)
      .then((res) => {
        const temp = res.data.filter((itm) => itm.username === user_name);
        setResults(temp);
      })
      .catch((er) => console.log(er));
  };

  useEffect(() => {
    if (accounts[0].name !== "") {
      setUserName(accounts[0].name);
      getResults(accounts[0].name);
    }
    // console.log(notificationitems);
    // setDisplayResults(notificationitems);
  }, []);

  return (
    <>
      <div className="row margintop1">
        <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
          <h4>RESULTS</h4>
        </div>
        {/*
          <tbody>
            {displayResults?.length === 0 ? (
              <tr>
                <th colSpan={7} className="text-center">
                  No results
                </th>
              </tr>
            ) : (
              ""
            )}
            {displayResults?.map((item, i) => {
              return (
                <tr key={`tr_${i}`}>
                  <td>{i + 1}</td>
                  <td>{item["name"]}</td>
                  <td>
                    Algorithm :{" "}
                    <strong>{item["input"]?.step1Data?.algorithm}</strong>
                    <br />
                    {item["input"]?.step2Data?.filename !== ""
                      ? `Filename : ${item["input"]?.step2Data?.filename}`
                      : ""}
                    {item["input"]?.step2Data?.sequencename !== ""
                      ? `Filename : ${item["input"]?.step2Data?.sequencename}`
                      : ""}
                    <br />
                    <strong>Tissue</strong> : {item["input"]?.step3Data?.tissue}
                    , <strong>Range</strong> :{" "}
                    {item["input"]?.step3Data?.n_sequences}
                  </td>
                  <td>{item["result"].length}</td>
                  <td>{item["createdOn"]}</td>
                  <td>{item["done"] ? "COMPLETED" : "PENDING"}</td>
                  <td>
                    <a
                      onClick={() => viewPage(item)}
                      title="View result details"
                      className="action_item"
                    >
                      <FontAwesomeIcon icon={faEye} />
                    </a>
                    <a
                      onClick={() => deleteItem(item["id"])}
                      title="Delete result details"
                      className="action_item"
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </a>
                  </td>
                </tr>
              );
            })}
          </tbody>
          */}
        <Resultspaginator itemsPerPage="5" items={results} />
      </div>
    </>
  );
};

export default Results;
