import Loading from "../utils/Loading";
import Dashboard from "../pages/Dashboard";
import ErrorComponent from "../utils/ErrorComponent";

// Msal imports
import { MsalAuthenticationTemplate } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { loginRequest } from "../authConfig";

export function Profile() {
  const authRequest = {
    ...loginRequest,
  };

  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Popup}
      authenticationRequest={authRequest}
      errorComponent={ErrorComponent}
      loadingComponent={Loading}
    >
      <Dashboard />
    </MsalAuthenticationTemplate>
  );
}
