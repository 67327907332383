import React, { useState } from "react";
import ReactPaginate from "react-paginate";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";

const Resultspaginator = ({ itemsPerPage, items }) => {
  const navigate = useNavigate();
  const [itemOffset, setItemOffset] = useState(0);

  const endOffset = itemOffset + parseInt(itemsPerPage);
  const currentItems = items.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(items.length / parseInt(itemsPerPage));

  const viewPage = (id) => {
    navigate(`/viewresult/${id}`);
  };

  const handlePageClick = (event) => {
    const newOffset = (event.selected * parseInt(itemsPerPage)) % items.length;
    setItemOffset(newOffset);
  };

  const timeformatter = (time) => {
    let temp = time.split(".");
    let formattedTime = temp[0].split("T");
    return formattedTime[0] + " " + formattedTime[1];
  };

  return (
    <>
      <table className="table table-striped">
        <thead>
          <tr>
            <th>Job Id</th>
            <th>Protein sequence name</th>
            <th>Selected parameters</th>
            <th>created At</th>
            {/* <th>CAI</th>
              <th>CAI Weight</th>
              <th>CPB</th>
              <th>CPB Weight</th>
              <th>ENC</th>
              <th>ENC Weight</th>
              <th>MFE</th>
              <th>MFE Weight</th>
              <th>MFEini</th>
              <th>MFEini Weight</th>
              <th>Algorithm</th>
              <th>No of sequences</th>
              <th>Sequence</th>
              <th>Tissue </th> */}
            <th>Status</th>
            <th>Results</th>
          </tr>
        </thead>
        <tbody>
          {currentItems.length > 0 &&
            currentItems.map((itm, i) => {
              return (
                <tr key={`key_${i}`}>
                  <td>{itm.jobId}</td>
                  <td>{itm.name}</td>
                  <td>
                    Algorithm: {itm.algorithm}
                    <br />
                    Tissue: {itm.tissue}
                    <br />
                    Number of sequences: {itm.number_sequences}
                  </td>
                  {/* <td>{itm.CAI}</td>
                    <td>{itm.CAIWeight}</td>
                    <td>{itm.CPB}</td>
                    <td>{itm.CPBWeight}</td>
                    <td>{itm.ENC}</td>
                    <td>{itm.ENCWeight}</td>
                    <td>{itm.MFE}</td>
                    <td>{itm.MFEWeight}</td>
                    <td>{itm.MFEini}</td>
                    <td>{itm.MFEiniWeight}</td>
                    <td>{itm.algorithm}</td>
                    <td>{itm.number_sequences}</td>
                    <td>{itm.proteinSequence}</td>
                    <td>{itm.tissue} </td> */}
                  <td className="createdat">{timeformatter(itm.createdAt)}</td>
                  <td>{itm.completed ? "COMPLETED" : "WAITING"}</td>
                  <td>
                    {itm.completed && (
                      <a onClick={() => viewPage(itm.jobId)}>
                        <FontAwesomeIcon icon={faEye} />
                      </a>
                    )}
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
      <ReactPaginate
        breakLabel="..."
        nextLabel=">"
        onPageChange={handlePageClick}
        pageRangeDisplayed={5}
        pageCount={pageCount}
        previousLabel="<"
        renderOnZeroPageCount={null}
        containerClassName={"pagination"}
        pageClassName={"page-item"}
        activeClassName={"active"}
      />
    </>
  );
};

export default Resultspaginator;
