import React, { useState, useEffect, lazy, Suspense, useContext } from "react";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";

import Launchmessage from "../elements/launchmessage/Launchmessage";
import Tabheader from "../elements/tabheader/Tabheader";
import Errormsg from "../elements/errormsg/Errormsg";
import Loader from "../elements/loader/Loader";
import { basePath, axiosOptions } from "../utils/constants";
import Usercontext from "../utils/Usercontext";
import axios from "axios";

const Algorithmselection = lazy(() =>
  import("../components/algorithmselection/Algorithmselection")
);
const Inputsequence = lazy(() =>
  import("../components/inputsequence/Inputsequence")
);
const Parameterselection = lazy(() =>
  import("../components/parameterselection/Parameterselection")
);
const Advancedparameters = lazy(() =>
  import("../components/advancedparameters/Advancedparameters")
);
const Finalmessage = lazy(() =>
  import("../components/finalmessage/Finalmessage")
);
const Popup = lazy(() => import("../elements/popup/Popup"));

const Dashboard = () => {
  const isLoggedIn = useIsAuthenticated();
  const location = useLocation();
  let navigateobj = useNavigate();
  const { accounts } = useMsal();
  const [userName, setUserName] = useState("");

  const [step1, setStep1] = useState("active");
  const [step2, setStep2] = useState("default");
  const [step3, setStep3] = useState("default");
  const [step4, setStep4] = useState("default");
  const [step5, setStep5] = useState("default");
  const [active, setActive] = useState("step1");
  const { initialInputdata, inputdata, setInputdata } = useContext(Usercontext);
  // const initialInputdata = {
  //   step1Data: {
  //     algorithm: "",
  //     status: "",
  //   },
  //   step2Data: {
  //     name: [],
  //     sequence: [],
  //     sequencename: "",
  //     sequencetxt: "",
  //     filename: "",
  //     status: "",
  //   },
  //   step3Data: {
  //     tissue: "",
  //     n_sequences: 0,
  //     status: "",
  //   },
  //   step4Data: {
  //     defaultsetting: false,
  //     desiredgccontent: true,
  //     desireducontent: true,
  //     mrnabindingremoval: true,
  //     weightfeaturefinalscore: false,
  //     finalscore: "",
  //     gccontent: 55,
  //     ucontent: 20,
  //     status: "",
  //   },
  // };
  // const [inputdata, setInputdata] = useState(initialInputdata);
  const [allresults, setAllresults] = useState([]);
  const [errorstatus, setErrorstatus] = useState({
    flag: false,
    type: "",
    message: "",
  });
  const [launchMessage, setLaunchMessage] = useState({
    show: false,
    valid: false,
    messagetxt: "",
  });
  const [seeresults, setSeeresults] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showpop, setShowpop] = useState({
    text: "",
    title: "",
    display: false,
  });

  const settingProcessStep = (step) => {
    setActive(step);
    if (step === "step1") {
      if (step1 !== "error" && step1 !== "success") setStep1("active");
      if (step2 !== "error" && step2 !== "success") setStep2("default");
      if (step3 !== "error" && step3 !== "success") setStep3("default");
      if (step4 !== "error" && step4 !== "success") setStep4("default");
      if (step5 !== "error" && step5 !== "success") setStep5("default");
    }
    if (step === "step2") {
      if (step1 !== "error" && step1 !== "success") setStep1("default");
      if (step2 !== "error" && step2 !== "success") setStep2("active");
      if (step3 !== "error" && step3 !== "success") setStep3("default");
      if (step4 !== "error" && step4 !== "success") setStep4("default");
      if (step5 !== "error" && step5 !== "success") setStep5("default");
    }
    if (step === "step3") {
      if (step1 !== "error" && step1 !== "success") setStep1("default");
      if (step2 !== "error" && step2 !== "success") setStep2("default");
      if (step3 !== "error" && step3 !== "success") setStep3("active");
      if (step4 !== "error" && step4 !== "success") setStep4("default");
      if (step5 !== "error" && step5 !== "success") setStep5("default");
    }
    if (step === "step4") {
      if (step1 !== "error" && step1 !== "success") setStep1("default");
      if (step2 !== "error" && step2 !== "success") setStep2("default");
      if (step3 !== "error" && step3 !== "success") setStep3("default");
      if (step4 !== "error" && step4 !== "success") setStep4("active");
      if (step5 !== "error" && step5 !== "success") setStep5("default");
    }
    if (step === "step5") {
      if (step1 !== "error" && step1 !== "success") setStep1("default");
      if (step2 !== "error" && step2 !== "success") setStep2("default");
      if (step3 !== "error" && step3 !== "success") setStep3("default");
      if (step4 !== "error" && step4 !== "success") setStep4("default");
      if (step5 !== "error" && step5 !== "success") setStep5("active");
    }
  };

  const settingdfltValues = () => {
    setStep1("active");
    setStep2("default");
    setStep3("default");
    setStep4("default");
    setStep5("default");
    setActive("step1");
    setInputdata(initialInputdata);
    setLaunchMessage({
      show: false,
      valid: false,
      messagetxt: "",
    });
    setErrorstatus({
      flag: false,
      type: "",
      message: "",
    });
    setShowpop({
      text: "",
      title: "",
      display: false,
    });
    setSeeresults(false);
    setAllresults([]);
  };

  const finalinputdata = async (key, data) => {
    if (key === "step1") {
      if (data.status === "error") {
        setStep1("error");
      } else if (data.status === "success") {
        setActive("step2");
        setStep1("success");
        setStep2("active");
        setInputdata({ ...inputdata, step1Data: data });
      }
      window.scrollTo(0, 0);
    } else if (key === "step2") {
      if (data.status === "error") {
        setStep2("error");
      } else if (data.status === "success") {
        setActive("step3");
        setStep2("success");
        setStep3("active");
        setInputdata({ ...inputdata, step2Data: data });
      }
      window.scrollTo(0, 0);
    } else if (key === "step3") {
      if (data.status === "error") {
        setStep3("error");
      } else if (data.status === "success") {
        setInputdata({ ...inputdata, step3Data: data });
        setActive("step4");
        setStep3("success");
        setStep4("active");
      }
      window.scrollTo(0, 0);
    } else if (key === "step4") {
      if (data.status === "error") {
        setStep4("error");
        window.scrollTo(0, 0);
      } else if (data.status === "success") {
        setInputdata({ ...inputdata, step4Data: data });
        if (
          inputdata.step1Data.status !== "success" ||
          inputdata.step2Data.status !== "success" ||
          inputdata.step3Data.status !== "success"
        ) {
          setErrorstatus({
            flag: true,
            type: "error",
            message: "Please fill all the forms",
          });
          window.scrollTo(0, 0);
        } else {
          setLoading(true);
          const responses = [];
          const tmpallresults = [];
          const successresponse = [];
          const errorresponse = [];
          if (inputdata.step2Data.sequence.length > 0) {
            for (let i = 0; i < inputdata.step2Data.sequence.length; i++) {
              const ipData = {
                name: inputdata.step2Data.sequencename,
                username: userName,
                algorithm: inputdata.step1Data.algorithm,
                tissue: inputdata.step3Data.tissue,
                // inputdata.step3Data.tissue === ""
                //   ? "Liver"
                //   : inputdata.step3Data.tissue,
                sequence: inputdata.step2Data.sequence[i],
                number_sequences: inputdata.step3Data.n_sequences,
              };
              await axios({
                url: `${basePath}optimiseJob`, //submit
                method: "POST",
                headers: axiosOptions,
                data: ipData,
              })
                .then((res) => {
                  console.log(res);
                  // responses.push(res.data);
                  // if (res.data.valid) {
                  //   successresponse.push(res.data);
                  //   let seq = allresults.filter(
                  //     (itm) => itm.sequence === inputdata.step2Data.sequence[i]
                  //   );
                  //   seq[0].done = res.data.done;
                  //   seq[0].valid = res.data.valid;
                  //   seq[0].jobid = res.data.jobid;
                  //   seq[0].result = res.data.result;
                  //   seq[0].message = res.data.message;
                  //   seq[0].input = inputdata;
                  //   tmpallresults.push(seq[0]);
                  // } else {
                  //   errorresponse.push(res.data);
                  // }
                })
                .catch((err) => {
                  setErrorstatus({
                    flag: true,
                    type: "error",
                    message: err,
                  });
                });
            }
            setAllresults(tmpallresults);
            //  All are invalid
            if (errorresponse.length === inputdata.step2Data.sequence.length) {
              errorresponse.forEach((errItem) => {
                setErrorstatus({
                  flag: true,
                  type: "error",
                  message: errItem.message,
                });
              });
              window.scrollTo(0, 0);
            }
            //  Some are invalid
            if (
              errorresponse.length !== 0 &&
              errorresponse.length < inputdata.step2Data.sequence.length
            ) {
              setErrorstatus({
                flag: true,
                type: "warning",
                message: `${errorresponse.length} / ${inputdata.step2Data.sequence.length} sequence failed. Continue with the valid sequnces.`,
              });
              window.scrollTo({
                top: document.body.scrollHeight,
                left: 0,
                behavior: "smooth",
              });
            }
            // All are valid
            if (successresponse.length > 0) {
              const message =
                "Launch this run. Results will be emailed to your monsanto email. You can also query using our database feature in the sidebar.";
              setLaunchMessage({
                show: true,
                valid: true,
                messagetxt: message,
              });
              setStep4("success");
              window.scrollTo(0, document.body.scrollHeight);
            }
            setLoading(false);
          }
          if (inputdata.step2Data.sequencetxt !== "") {
            const ipData = {
              name: inputdata.step2Data.sequencename,
              username: userName,
              algorithm: inputdata.step1Data.algorithm,
              tissue: inputdata.step3Data.tissue,
              // inputdata.step3Data.tissue === ""
              //   ? "Liver"
              //   : inputdata.step3Data.tissue,
              sequence: inputdata.step2Data.sequencetxt,
              number_sequences: inputdata.step3Data.n_sequences,
            };
            await axios
              .post(`${basePath}optimiseJob`, ipData, axiosOptions)
              .then((results) => {
                if (results.data.status !== "" && results.status == 200) {
                  setErrorstatus({
                    flag: true,
                    type: "success",
                    message: `Success! ${results.data.status}`,
                  });
                  setStep4("success");
                  setTimeout(() => {
                    setShowpop({
                      display: true,
                      title: "Submitted",
                      text: "Do you want to submit a new sequence?",
                    });
                  }, 5000);
                } else {
                  setErrorstatus({
                    flag: true,
                    type: "error",
                    message: results?.data?.message,
                  });
                }
                // if (results.data.valid) {
                //   const message =
                //     "Launch this run. Results will be emailed to your monsanto email. You can also query using our database feature in the sidebar.";
                //   setLaunchMessage({
                //     show: true,
                //     valid: true,
                //     messagetxt: message,
                //   });
                //   setStep4("success");
                //   let seq = allresults[0];
                //   seq.input = inputdata;
                //   seq.done = results.data.done;
                //   seq.jobid = results.data.jobid;
                //   seq.valid = results.data.valid;
                //   seq.result = results.data.result;
                //   seq.message = results.data.message;
                //   setAllresults([seq]);
                //   window.scrollTo(0, document.body.scrollHeight);
                // }
              })
              .catch((error) => {
                setErrorstatus({
                  flag: true,
                  type: "error",
                  message: `Error. ${error}`,
                });
              });
            setLoading(false);
          }
        }
      }
    } else if (key === "step5") {
      setLoading(true);
      let tempallresults = [];
      for (let i = 0; i < allresults.length; i++) {
        await axios
          .get(`${basePath}jobstatus/${allresults[i].jobid}`, axiosOptions)
          .then((res) => {
            let seq = allresults.filter(
              (itm) => itm.jobid === allresults[i].jobid
            );
            seq[0].id = Date.now();
            seq[0].read = 0;
            seq[0].done = res.data.done;
            seq[0].result = res.data.result;
            seq[0].createdOn = new Date().toLocaleString().replace(",", "");
            tempallresults.push(seq[0]);
          })
          .catch((err) => {
            setErrorstatus({
              flag: true,
              type: "error",
              message: err,
            });
          });
      }
      setAllresults(tempallresults);
      setActive("step5");
      setStep5("success");
      setLaunchMessage({
        show: false,
        valid: false,
        messagetxt: "",
      });
      setErrorstatus({
        flag: false,
        type: "",
        message: "",
      });
      setSeeresults(true);
      window.scrollTo(0, 0);
      setLoading(false);
    } else if (key === "step6") {
      settingdfltValues();
    }
  };

  useEffect(() => {
    const currentAccount = accounts[0];
    if (currentAccount) {
      setUserName(currentAccount.name);
    }
    if (isLoggedIn && location.pathname === "/") {
      navigateobj("/dashboard");
    }
  }, []);

  if (!isLoggedIn) {
    return <Navigate replace to="/" />;
  } else {
    return (
      <>
        <Tabheader
          step1={step1}
          step2={step2}
          step3={step3}
          step4={step4}
          step5={step5}
          settingProcessStep={settingProcessStep}
        />
        {active === "step1" ? (
          <Suspense fallback={<Loader />}>
            <Algorithmselection
              settingerrorstatus={setErrorstatus}
              finalinputdata={finalinputdata}
              data={inputdata.step1Data}
              allresultsfinal={allresults}
              setAllresultsfinal={setAllresults}
            />
          </Suspense>
        ) : active === "step2" ? (
          <Suspense fallback={<Loader />}>
            <Inputsequence
              settingerrorstatus={setErrorstatus}
              finalinputdata={finalinputdata}
              data={inputdata.step2Data}
              showloader={setLoading}
              allresultsfinal={allresults}
              setAllresultsfinal={setAllresults}
            />
          </Suspense>
        ) : active === "step3" ? (
          <Suspense fallback={<Loader />}>
            <Parameterselection
              settingerrorstatus={setErrorstatus}
              finalinputdata={finalinputdata}
              data={inputdata.step3Data}
              chkdata={inputdata.step1Data}
              allresultsfinal={allresults}
              setAllresultsfinal={setAllresults}
            />
          </Suspense>
        ) : active === "step4" ? (
          <Suspense fallback={<Loader />}>
            <Advancedparameters
              settingerrorstatus={setErrorstatus}
              finalinputdata={finalinputdata}
              data={inputdata.step4Data}
              allresultsfinal={allresults}
              setAllresultsfinal={setAllresults}
            />
          </Suspense>
        ) : active === "step5" ? (
          <Suspense fallback={<Loader />}>
            <Finalmessage
              seeresults={seeresults}
              finalinputdata={finalinputdata}
              allresultsfinal={allresults}
              setAllresultsfinal={setAllresults}
            />
          </Suspense>
        ) : (
          ""
        )}
        {inputdata.step1Data.status === "success" &&
        inputdata.step2Data.status === "success" &&
        inputdata.step3Data.status === "success" &&
        inputdata.step4Data.status === "success" &&
        active === "step4" &&
        launchMessage.show ? (
          <Launchmessage
            launchMessage={launchMessage}
            finalinputdata={finalinputdata}
          />
        ) : (
          ""
        )}
        <Errormsg errorstatus={errorstatus} setErrorstatus={setErrorstatus} />
        {loading && <Loader />}
        {showpop.display ? (
          <Popup
            title={showpop.title}
            text={showpop.text}
            cancelfn={() => {
              setShowpop({
                text: "",
                title: "",
                display: false,
              });
              setErrorstatus({
                flag: false,
                type: "",
                message: "",
              });
            }}
            submitfn={settingdfltValues}
          />
        ) : (
          ""
        )}
      </>
    );
  }
};

export default Dashboard;
