import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

const Tabheader = (props) => {
  return (
    <>
      <div className="row">
        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
          <div className="bs-stepper">
            <div className="bs-stepper-header">
              <div className="step">
                <span
                  id="step1"
                  // type="button"
                  className={`step-trigger ${props.step1}`}
                  onClick={() => props.settingProcessStep("step1")}
                >
                  <label className="bs-stepper-circle">
                    {props.step1 === "success" ? (
                      <FontAwesomeIcon icon={faCheck} />
                    ) : (
                      "1"
                    )}
                  </label>
                  <label className="bs-stepper-label">
                    Algorithm Selection
                  </label>
                </span>
              </div>
              <div className="line"></div>
              <div className="step">
                <span
                  id="step2"
                  // type="button"
                  className={`step-trigger ${props.step2}`}
                  onClick={() => props.settingProcessStep("step2")}
                >
                  <label className="bs-stepper-circle">
                    {props.step2 === "success" ? (
                      <FontAwesomeIcon icon={faCheck} />
                    ) : (
                      "2"
                    )}
                  </label>
                  <label className="bs-stepper-label">Input Sequence</label>
                </span>
              </div>
              <div className="line"></div>
              <div className="step">
                <span
                  id="step3"
                  // type="button"
                  className={`step-trigger ${props.step3}`}
                  onClick={() => props.settingProcessStep("step3")}
                >
                  <label className="bs-stepper-circle">
                    {props.step3 === "success" ? (
                      <FontAwesomeIcon icon={faCheck} />
                    ) : (
                      "3"
                    )}
                  </label>
                  <label className="bs-stepper-label">
                    Parameter Selection
                  </label>
                </span>
              </div>
              <div className="line"></div>
              <div className="step">
                <span
                  id="step4"
                  // type="button"
                  className={`step-trigger ${props.step4}`}
                  onClick={() => props.settingProcessStep("step4")}
                >
                  <label className="bs-stepper-circle">
                    {props.step4 === "success" ? (
                      <FontAwesomeIcon icon={faCheck} />
                    ) : (
                      "4"
                    )}
                  </label>
                  <label className="bs-stepper-label">
                    Advanced Parameters
                  </label>
                </span>
              </div>
              {/*
              <div className="line"></div>
              <div className="step">
                <span
                  id="step5"
                  // type="button"
                  className={`step-trigger ${props.step5}`}
                  onClick={() => props.settingProcessStep("step5")}
                >
                  <label className="bs-stepper-circle">
                    {props.step5 === "success" ? (
                      <FontAwesomeIcon icon={faCheck} />
                    ) : (
                      "5"
                    )}
                  </label>
                  <label className="bs-stepper-label">Results</label>
                </span>
              </div>
              */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Tabheader;
