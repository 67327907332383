import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import LoginBody from "./LoginBody";
import { Profile } from "./Profile";

const Home = () => {
  return (
    <>
      <AuthenticatedTemplate>
        <Profile />
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <LoginBody />
      </UnauthenticatedTemplate>
    </>
  );
};

export default Home;
