import { useState } from "react";
import { useMsal } from "@azure/msal-react";

export const SignOutButton = () => {
  const { instance } = useMsal();
  const [openobj, setOpenobj] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleLogout = (logoutType) => {
    setAnchorEl(null);

    if (logoutType === "popup") {
      instance.logoutPopup();
    } else if (logoutType === "redirect") {
      instance.logoutRedirect();
    }
  };

  const handleAccountSelection = () => {
    setAnchorEl(null);
    setOpenobj(true);
  };

  return (
    <>
      <a onClick={() => handleAccountSelection()} key="switchAccount">
        Switch Account
      </a>
      <a onClick={() => handleLogout("popup")} key="logoutPopup">
        Logout using Popup
      </a>
      <a onClick={() => handleLogout("redirect")} key="logoutRedirect">
        Logout using Redirect
      </a>
    </>
  );
};
