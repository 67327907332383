import { Button } from "@element/react-components";

const Errormsg = (props) => {
  return (
    <>
      {props.errorstatus.flag ? (
        <div
          className={
            props.errorstatus.type === "error"
              ? "toastmessage toasterror"
              : props.errorstatus.type === "success"
              ? "toastmessage toastsuccess"
              : "toastmessage toastwarning"
          }
        >
          <div>
            {props.errorstatus.message}{" "}
            <Button
              type="submit"
              label="DISMISS"
              variant="outlined"
              onClick={() =>
                props.setErrorstatus({
                  type: "",
                  flag: false,
                  message: "",
                })
              }
            />
            {/*
            <button
              onClick={() =>
                props.setErrorstatus({
                  flag: false,
                  type: "",
                  message: "",
                })
              }
            >
              DISMISS
            </button>
            */}
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default Errormsg;
