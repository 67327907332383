import { useState, createContext } from "react";

const Usercontext = createContext();

export function UserProvider({ children }) {
  // const [notificationitems, setNotificationitems] = useState([]);
  // value={{ notificationitems, setNotificationitems }}
  const [inputmethod, setInputmethod] = useState("upload");
  const initialInputdata = {
    step1Data: {
      algorithm: "",
      status: "",
    },
    step2Data: {
      name: [],
      sequence: [],
      sequencename: "",
      sequencetxt: "",
      filename: "",
      status: "",
    },
    step3Data: {
      tissue: "",
      n_sequences: 0,
      status: "",
    },
    step4Data: {
      defaultsetting: false,
      desiredgccontent: true,
      desireducontent: true,
      mrnabindingremoval: true,
      weightfeaturefinalscore: false,
      finalscore: "",
      gccontent: 55,
      ucontent: 20,
      status: "",
    },
  };
  const [inputdata, setInputdata] = useState(initialInputdata);

  return (
    <Usercontext.Provider
      value={{
        initialInputdata,
        inputdata,
        setInputdata,
        inputmethod,
        setInputmethod,
      }}
    >
      {children}
    </Usercontext.Provider>
  );
}

export default Usercontext;
