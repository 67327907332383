import LoaderImage from "../../assets/images/loading.gif";

const Loader = () => {
  return (
    <>
      <div className="loaderdiv">
        <img src={LoaderImage} alt="Loading..." />
      </div>
    </>
  );
};

export default Loader;
