import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import App from "./App";
// MSAL imports
import { PublicClientApplication, EventType } from "@azure/msal-browser";
import { msalConfig, loginRequest } from "./authConfig";

import "../src/assets/css/bs-stepper.min.css";
import "./index.css";
import "@element/themes/bayer";

export const msalInstance = new PublicClientApplication(msalConfig);

msalInstance.initialize().then(() => {
  if (
    !msalInstance.getActiveAccount() &&
    msalInstance.getAllAccounts().length > 0
  ) {
    msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
  }

  msalInstance.enableAccountStorageEvents();

  msalInstance.addEventCallback((event) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
      const account = event.payload.account;
      msalInstance.setActiveAccount(account);
    }
    if (
      event.eventType === EventType.SSO_SILENT_FAILURE &&
      event.error?.errorCode === "monitor_window_timeout"
    ) {
      console.log("here error");
      msalInstance.acquireTokenRedirect({ ...loginRequest });
    }
  });

  // handle auth redired/do all initial setup for msal
  msalInstance
    .handleRedirectPromise()
    .then((authResult) => {
      // Check if user signed in
      const account = msalInstance.getActiveAccount();
      // if (!account) {
      //   // redirect anonymous user to login page
      //   msalInstance.loginRedirect();
      // }
    })
    .catch((err) => {
      // TODO: Handle errors
      console.log(err);
    });

  const container = document.getElementById("root");
  const root = ReactDOM.createRoot(container);

  root.render(
    <Router>
      <App pca={msalInstance} />
    </Router>
  );
});
